<template>
  <div class="position-page">
    <el-button type="primary" style="margin-bottom: 16px;" @click="visible = true">新增</el-button>
    <el-collapse v-model="activeId">
      <el-collapse-item v-for="item of positionList" :key="item.id" :title="item.positionName" :name="item.id.toString()">
        <span>技能要求</span>
        <pre>{{item.skill}}</pre>
        <span>任职要求</span>
        <pre>{{item.base}}</pre>
        <el-button-group style="margin-top: 16px;">
          <el-button size="mini" icon="el-icon-edit" type="primary" @click="handleEdit(item)"/>
          <el-button size="mini" icon="el-icon-delete" type="primary" @click="handleDelete(item.id)"/>
        </el-button-group>
      </el-collapse-item>
    </el-collapse>
    <el-dialog title="新增职位" :visible.sync="visible" width="30%" @closed="handleClosed">
      <el-form :model="positionForm" inline>
        <el-form-item label="职位名称">
          <el-input v-model="positionForm.positionName" type="text" />
        </el-form-item>
        <el-form-item label="技能要求">
          <el-input v-model="positionForm.skill" type="textarea" :rows="4" />
        </el-form-item>
        <br>
        <el-form-item label="任职要求">
          <el-input v-model="positionForm.base" type="textarea" :rows="4" />
        </el-form-item>
        <br>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false" @close="visible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改职位" :visible.sync="updateVisible" width="30%" @closed="handleClosed">
      <el-form :model="positionForm" inline>
        <el-form-item label="职位名称">
          <el-input v-model="positionForm.positionName" type="text" />
        </el-form-item>
        <el-form-item label="技能要求">
          <el-input v-model="positionForm.skill" type="textarea" :rows="4" />
        </el-form-item>
        <br>
        <el-form-item label="任职要求">
          <el-input v-model="positionForm.base" type="textarea" :rows="4" />
        </el-form-item>
        <br>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import axios from "axios";

  export default {
    data() {
      return {
        visible: false,
        updateVisible: false,
        activeId: '',
        positionForm: {
          id: '',
          positionName: '',
          skill: '',
          base: ''
        },
        positionList: []
      }
    },
    mounted() {
      this.getPositionList()
    },
    methods: {
      handleClosed() {
        this.productForm = {}
      },
      handleEdit(row) {
        this.positionForm = { ...row }
        this.updateVisible = true
      },
      getPositionList() {
        axios.get('/api/position').then(data => {
          this.positionList = data.data
        })
      },
      handleSubmit() {
        const params = {}
        const { id, positionName, skill, base } = this.positionForm
        if (!!positionName) params.positionName = positionName
        if (!!skill) params.skill = skill
        if (!!base) params.base = base
        if (this.visible) {
          axios.post('/api/position', params).then(data => {
            if (data.data === 'success') {
              this.$notify.success('新增成功')
              this.getPositionList()
              this.visible = false
            } else {
              this.$notify.error('新增失败')
            }
          })
        }
        if (this.updateVisible) {
          axios.put('/api/position', { id: id, positionName: positionName, skill: skill, base: base }).then(data => {
            if (data.data === 'success') {
              this.$notify.success('修改成功')
              this.getPositionList()
              this.updateVisible = false
            } else {
              this.$notify.error('修改失败')
            }
          })
        }
      },
      handleDelete(id) {
        const flag = window.confirm('是否删除')
        if (!flag) return
        axios.delete(`/api/position/${id}`,).then(data => {
          if (data.data === 'success') {
            this.$notify.success('删除成功')
            this.getPositionList()
          } else {
            this.$notify.error('删除失败')
          }
        })
      },
    }
  }
</script>
